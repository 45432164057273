import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { TiArrowUpOutline, TiArrowUpThick } from "react-icons/ti";
import { MdKeyboardBackspace, MdMessage } from "react-icons/md";
import { HiEye } from "react-icons/hi";
import { IoMdShare } from "react-icons/io";
import ReplyTweet from "./ReplyTweet";
import { FaFacebook, FaWhatsapp } from "react-icons/fa6";
import NotFound from "../../assets/Images/not-found.png";
import { useSelector } from "react-redux";
import { BsTwitterX } from "react-icons/bs";
import { updateLikeList } from "../../helper/fetchTweetData";

const ImageViewer = ({
  imageViewer,
  setImageViewer,
  postData,
  setPostData,
  postId,
}) => {
  const [tweet, setTweet] = useState(false);
  const user = useSelector((state) => state.user.data);

  const userData = useSelector((state) => state.user.data?.userData);
  const tweetVoice = useSelector((state) => state.user.tweetVoice);
  const tweetCountry = useSelector((state) => state.user.tweetCountry);
  const tweetEnglish = useSelector((state) => state.user.englishPost);

  useEffect(() => {
    if (userData && Object.values(postData).length) {
      const tweetVoiceData = tweetVoice[`${postData.id}`];
      const tweetCountryData = tweetCountry[`${postData.id}`];
      const englishPostData = tweetEnglish[`${postData.id}`];

      if (tweetVoiceData) {
        setPostData(tweetVoiceData);
      } else if (tweetCountryData) {
        setPostData(tweetCountryData);
      } else if (englishPostData) {
        setPostData(englishPostData);
      }
    }
  }, [userData, tweetVoice, tweetCountry, tweetEnglish]);

  const handleLikeImage = async () => {
    const data = await updateLikeList(postId, userData);
    setPostData(data)
  };

  return (
    <div>
      <Transition.Root show={imageViewer} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={setImageViewer}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000] bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-[999] w-screen overflow-y-auto">
            <div className="flex min-h-full justify-center p-2 text-center items-center sm:p-0 w-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] p-[20px]">
                  <div>
                    <button
                      onClick={() => setImageViewer(false)}
                      className="text-[24px] mb-3 outline-none text-[#EF9595]"
                    >
                      <MdKeyboardBackspace />
                    </button>

                    {postData?.imagePath &&
                      /\.(jpg|jpeg|png|svg)(?=\?alt=media)/i.test(
                        postData?.imagePath
                      ) ? (
                      <img
                        src={postData?.imagePath}
                        alt="post_image"
                        onError={({ currentTarget }) => {
                          currentTarget.src = NotFound;
                          currentTarget.classList = "opacity-60 rounded-[10px]";
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    <div className="flex gap-[24px] mt-[20px] flex-wrap items-center">
                      <button
                        className={`flex sm:gap-[16px] gap-[6px] text-[16px] items-center `}
                        onClick={() => handleLikeImage()}
                      >
                        {postData?.likeList?.includes(
                          user?.userData?.userId
                        ) ? (
                          <TiArrowUpThick className="sm:text-[24px] text-[20px] text-[green]" />
                        ) : (
                          <TiArrowUpOutline className="sm:text-[24px] text-[20px] text-[#5c5c5c]" />
                        )}
                        {postData?.likeList?.length || 0}
                      </button>
                      <button
                        onClick={() => {
                          setTweet(true);
                          setImageViewer(false);
                        }}
                        className="flex  sm:gap-[16px] gap-[6px] text-[16px]"
                      >
                        <MdMessage className="sm:text-[24px] text-[20px] text-[#5c5c5c]" />
                        {postData?.replyTweetKeyList?.length || 0}
                      </button>

                      <button className="flex  sm:gap-[16px] gap-[6px] text-[16px]">
                        <HiEye className="sm:text-[24px] text-[20px] text-[#5c5c5c]" />
                        {postData?.viewsList && postData?.viewsList?.length * 3}
                      </button>
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div className="flex items-center">
                          <Menu.Button className="text-[14px]">
                            <IoMdShare className="sm:text-[24px] text-[20px] text-[#5c5c5c]" />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href={`https://www.facebook.com/sharer/sharer.php?u=https://aqrableek.com/posts/${postData.id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700"
                                    }
                                  >
                                    <div className="px-4 py-2 text-sm flex gap-2 cursor-pointer">
                                      <FaFacebook className="text-blue-600" />
                                      Share on Facebook
                                    </div>
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href={`https://twitter.com/intent/tweet?url=https://aqrableek.com/posts/${postData.id
                                      }&text=${encodeURIComponent(
                                        postData.description
                                      )}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700"
                                    }
                                  >
                                    <div className="px-4 py-2 text-sm flex gap-2 cursor-pointer">
                                      <BsTwitterX className="text-black" />
                                      Share on Twitter
                                    </div>
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    // href={`/post/${item.id}`}
                                    href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                                      "Check out this post! " +
                                      "https://aqrableek.com/posts/" +
                                      postData.id
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700"
                                    }
                                  >
                                    <div className="px-4 py-2 text-sm flex gap-2 cursor-pointer">
                                      <FaWhatsapp className="text-green-600" />
                                      Share on WhatsApp
                                    </div>
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ReplyTweet tweet={tweet} setTweet={setTweet} postId={postId} />
    </div>
  );
};

export default ImageViewer;
