import {
  get,
  getDatabase,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  runTransaction,
  set,
  update,
} from "firebase/database";
import "firebase/database";
import { getTodayDate, uploadImageToStorage } from "./uploadData";
import moment from "moment";

const database = getDatabase();

const updatePostLikeList = (postRef, userData) => {
  return runTransaction(postRef, (post) => {
    if (post) {
      if (!post.likeList) {
        post.likeList = [];
      }

      const index = post.likeList.indexOf(userData.userId);
      if (index === -1) {
        post.likeCount = (post.likeCount || 0) + 1;
        post.likeList.push(userData.userId);
      } else {
        post.likeCount -= 1;
        post.likeList.splice(index, 1);
      }
    }
    return post;
  })
    .then((snapshot) => snapshot)
    .catch((error) => {
      console.error("Like status update failed: ", error);
      throw error;
    });
};

export const updateLikeList = async (postId, userData) => {
  let responseData = {};
  const paths = [
    `tweetVoice/${userData.wordslang}/${postId}`,
    `tweetVoice/English worlds/${postId}`,
  ];

  const promises = paths.map(async (path) => {
    const postRef = ref(database, path);
    const snapshot = await get(postRef);
    if (snapshot.exists()) {
      await updatePostLikeList(postRef, userData);
      const updatedSnapshot = await get(postRef);
      if (updatedSnapshot.exists()) {
        const postData = updatedSnapshot.val();
        return {
          id: postId,
          ...postData,
        };
      }
    }
    return null;
  });

  const results = await Promise.all(promises);
  responseData = results.find(result => result !== null) || {};
  return responseData;
};


export const updateUserData = async (userDetails, userProfile) => {
  const {
    firstName,
    lastName,
    email,
    displayName,
    dob,
    country,
    wordslang,
    gender,
    age,
    bio,
    updatedAt,
    image,
  } = userDetails;

  let imageUrl;

  if (image) {
    imageUrl = await uploadImageToStorage(image).catch(console.error);
  }

  const updateObject = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    displayName: displayName,
    dob: dob,
    country: country,
    wordslang: wordslang,
    gender: gender,
    age: age || "",
    bio: bio || "",
    updatedAt: updatedAt,
    userId: userProfile.userId,
  };
  if (imageUrl) {
    updateObject.profilePic = imageUrl;
  }
  try {
    const userRef = ref(database, "/profile/" + userProfile.userId);
    await update(userRef, updateObject);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const createdDate = () => {
  const currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss.SSSSSS") + "Z";
  return currentDateTime;
};

// export const replyCommentData = async (
//   postId,
//   inputValue,
//   createdAt,
//   fileName,
//   userData
// ) => {
//   try {
//     const updatedPostData1 = await tryUpdatePost(
//       `tweetVoice/${userData.wordslang}/${postId}`,
//       inputValue,
//       createdAt,
//       userData.userId,
//       fileName, userData
//     );

//     const updatedPostData2 = await tryUpdatePost(
//       `tweetVoice/English worlds/${postId}`,
//       inputValue,
//       createdAt,
//       userData.userId,
//       fileName, userData
//     );

//     // const updatedPostData3 = await tryUpdatePost(
//     //   `tweetVoice/${userData.country}/${postId}`,
//     //   inputValue,
//     //   createdAt,
//     //   userData.userId,
//     //   fileName, userData
//     // );

//   } catch (error) {
//     console.error("Failed to reply to comment", error);
//     throw error;
//   }
// };

export const replyCommentData = async (
  postId,
  inputValue,
  createdAt,
  fileName,
  userData
) => {
  try {
    let updatedPostData = null;

    try {
      updatedPostData = await tryUpdatePost(
        `tweetVoice/${userData.wordslang}/${postId}`,
        inputValue,
        createdAt,
        userData.userId,
        fileName,
        userData,
        postId
      );
    } catch (error) {
      console.error(`Error updating post in collection ${userData.wordslang}:`, error);
    }

    if (!updatedPostData) {
      try {
        updatedPostData = await tryUpdatePost(
          `tweetVoice/English worlds/${postId}`,
          inputValue,
          createdAt,
          userData.userId,
          fileName,
          userData,
          postId
        );
      } catch (error) {
        console.error("Error updating post in English worlds collection:", error);
        // Handle error if needed
      }
    }

    return updatedPostData;
  } catch (error) {
    console.error("Failed to reply to comment", error);
    throw error; // Rethrow the error to handle it where replyCommentData is called
  }
};

const tryUpdatePost = async (path, inputValue, createdAt, userId, fileName, userData, postId) => {
  const currentDateTime = getTodayDate(0);
  const postRef = ref(database, path);
  const snapshot = await get(postRef);

  let commentDataId = null;
  let updatedPostData = null
  if (snapshot.exists()) {
    let postData = snapshot.val();

    if (!Array.isArray(postData.replyTweetKeyList)) {
      postData.replyTweetKeyList = [];
    }
    const newCommentData = {
      description: inputValue,
      likeCount: 0,
      commentCount: 0,
      retweetCount: 0,
      Subject: "0",
      userId: userId,
      createdAt: currentDateTime,
      parentkey: path.substring(path.lastIndexOf('/') + 1),
      user: {
        displayName: userData.displayName,
        isOnline: false,
        isVerified: false,
        userName: userData.userName || "",
        userId: userId,
        age: userData.age || "",
        profilePic: userData.profilePic || "",
      },
    };
    if (fileName !== "") {
      try {
        const imageUrl = await uploadImageToStorage(fileName);
        newCommentData.imageUrl = imageUrl;
      } catch (error) {
        console.error("Error uploading image: ", error);
      }
    }
    const rePath = path.substring(0, path.lastIndexOf('/'));
    const newCommentRef = push(ref(database, `${rePath}`));
    await set(newCommentRef, newCommentData);
    commentDataId = newCommentRef.key;

    postData.replyTweetKeyList.push(commentDataId);
    postData.retweetCount = (postData.retweetCount || 0) + 1;

    await update(postRef, postData);
    if (fileName !== "") {
      try {
        const imageUrl = await uploadImageToStorage(fileName);
        newCommentData.imageUrl = imageUrl;
      } catch (error) {
        console.error("Error uploading image: ", error);
      }
    }
    const updatedPostSnapshot = await get(postRef);
    updatedPostData = updatedPostSnapshot.val();

    // Add postId to updatedPostData
    updatedPostData.id = postId;

  }
  return updatedPostData
};

export const getVoiceData = async (userData) => {
  const tweetVoiceRef = ref(database, `tweetVoice/${userData.wordslang}`);
  const recentTweetVoiceQuery = query(tweetVoiceRef, orderByChild("createdAt"));
  let tweetVoiceData = {};
  onValue(recentTweetVoiceQuery, (snapshot) => {
    if (snapshot.exists()) {
      tweetVoiceData = snapshot.val();
    }
  });

  return tweetVoiceData;
};
